import React from 'react';
import PropTypes from 'prop-types';

import { StyledLink } from './NavigationItem.styles';

const NavigationItem = ({ link, clicked }) => {
  return (
    <StyledLink
      onClick={clicked}
      to={`${link.split(' ').join('-').toLowerCase()}`}
      offset={-100}
      spy={true}
      smooth={true}
    >
      {link}
    </StyledLink>
  );
};

NavigationItem.propTypes = {};

export default NavigationItem;
