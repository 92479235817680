import PropTypes from 'prop-types';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

//Styles
import {
  StyledHeader,
  StyledImage,
  TextBoxContainer,
  MainTitle,
  Description,
} from './Header.styles';

//Common
import { Container } from '../../components/common/elements';

const Header = () => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "banner.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxHeight: 1080) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <StyledHeader>
      <StyledImage fluid={file.childImageSharp.fluid} />
      <Container>
        <TextBoxContainer>
          <MainTitle>
            Firma Usługowo-Budowlana<br></br>DOMIN
          </MainTitle>
          <Description>
            Witamy na naszej stronie, Zapraszamy do zapoznania się z ofertą,
            którą mamy okazję Państwu przedstawić i zachęcamy do współpracy.
          </Description>
        </TextBoxContainer>
      </Container>
    </StyledHeader>
  );
};
Header.propTypes = {};

Header.defaultProps = {};

export default Header;
