import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyles = createGlobalStyle`
  ${normalize}

  :root {
    --white: #fff;
    --black: #000;
    --main:#C01F2F;
    --secondary: #393A3B;
    --navyBlue: #2B2D42;
    --darkNavyBlue: #101d2c;
    --darkGrey: #8D99AE;
    --lightGrey: #EDF2F4;
    --veryLightGrey: #f9f7f6;
    --darkYellow: #f3bb53;
  }

  html {
      font-size: 62.5%; //1rem = 10px
      box-sizing: border-box;
      line-height: 1.15;
    }
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
   }

  ::selection {
    background: var(--lightGrey);
  }

  body {
    box-sizing: border-box;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: 'Josefin Sans', Montserrat;
   }
   h1,h2,h3,h4,h5,h6 {
     margin: 0;
     padding: 0;
     margin-block-start: 0;
     margin-block-end: 0;
     margin-inline-start: 0px;
     margin-inline-end: 0px;
   }

`;

export default GlobalStyles;
