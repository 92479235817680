import styled from 'styled-components';

export const StyledNavigation = styled.nav`
  width: 100%;
  max-width: 100vw;
  z-index: 15;
  background: var(--white);
  position: fixed;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-x: hidden;
  justify-content: space-between;
  user-select: none;
  height: ${({ isMobile }) => (isMobile ? '6rem' : '10rem')};
`;
