import styled from 'styled-components';
import { Link } from 'react-scroll';

export const StyledLink = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const LogoContainer = styled.div`
  width: 10rem;
  height: 7rem;

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 8rem;
    height: 5rem;
  }

  & > svg {
    width: 100%;
    height: 100%;
  }
`;
