const theme = {
  mediaQueries: {
    smallest: `only screen and (max-width: 25em)`, //400px
    smaller: 'only screen and (max-width: 31.25em)', //500px
    small: 'only screen and (max-width: 37.5em)', // 600px
    smallMedium: 'only screen and (max-width: 43.75em)', //700px
    medium: 'only screen and (max-width: 56.25em)', //900px
    mediumLarge: 'only screen and (max-width:60.625em)', //970px
    large: 'only screen and (max-width: 65.625em)', //1050px
    larger: 'only screen and (max-width: 68.75em)', //1100px
    largest: 'only screen and (max-width: 75em)', //1200px
    massive: 'only screen and (max-width: 78.125em)', //1250px
    //-> Large to small
  },
};

export default theme;
