import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import GlobalStyles from '../../utils/styles/globalStyles';
import theme from '../../utils/styles/theme';

//Components
import Header from '../../sections/header/Header';
import NavigationBar from '../../components/navigation-bar/NavigationBar';
import SEO from '../../components/seo/SEO';

//Styles
import { LayoutWrapper, StyledMain } from './Layout.styles';

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <LayoutWrapper>
        <NavigationBar />
        <SEO />
        <GlobalStyles />
        <StyledMain>{children}</StyledMain>
      </LayoutWrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
