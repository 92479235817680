import React from 'react';
import PropTypes from 'prop-types';

//Components
import NavigationItem from './navigation-item/NavigationItem';

//Styles
import { LinksContainer } from './NavigationItems.styles';

const navigationLinks = ['Co Oferujemy', 'Dlaczego my?', 'Galeria', 'Kontakt'];

const NavigationItems = ({ mobile, clicked }) => {
  const renderNavigationLinks = () =>
    navigationLinks.map((item, index) => (
      <NavigationItem
        key={navigationLinks[index]}
        link={navigationLinks[index]}
        clicked={clicked}
      />
    ));

  return (
    <LinksContainer mobile={mobile}>{renderNavigationLinks()}</LinksContainer>
  );
};

NavigationItems.propTypes = {
  mobile: PropTypes.bool,
  clicked: PropTypes.func,
};

export default NavigationItems;
