import React from 'react';

//Logos
import DominLogoSmall from '../../images/logo/dominLogoSmall.svg';

//Styles
import { StyledLink, LogoContainer } from './Logo.styles';

const Logo = () => {
  return (
    <StyledLink to="navigationBar" smooth={true} spy={true}>
      <LogoContainer>
        <DominLogoSmall />
      </LogoContainer>
    </StyledLink>
  );
};

export default Logo;
