import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  color: var(--secondary);
`;

export const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 10rem;

  @media ${({ theme }) => theme.mediaQueries.small} {
    margin-top: 6rem;
  }
`;
