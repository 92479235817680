import React from 'react';
import PropTypes from 'prop-types';

import { StyledMenu } from './MobileMenu.styles';
import NavigationItems from '../navigation-items/NavigationItems';

const MobileMenu = ({ open, handleItemClick }) => {
  return (
    <StyledMenu open={open}>
      <NavigationItems mobile clicked={handleItemClick} />
    </StyledMenu>
  );
};

MobileMenu.propTypes = {
  open: PropTypes.bool,
};

MobileMenu.defaultProps = {
  open: false,
};

export default MobileMenu;
