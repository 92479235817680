import styled from 'styled-components';
import Img from 'gatsby-image';

export const StyledHeader = styled.header`
  width: 100%;
  height: calc(100vh - 10rem);
  position: relative;
  display: flex;

  @media ${({ theme }) => theme.mediaQueries.small} {
    height: calc(100vh - 6rem);
  }
`;

export const StyledImage = styled(Img)`
  position: absolute !important;
  filter: brightness(50%) saturate(120%);
  height: calc(100vh - 10rem);
  width: 100%;
  z-index: 1;
`;

export const TextBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  top: 25%;
  /* margin-top: 20rem; */
  position: absolute;
  margin-left: -2rem;
  width: 80%;
  color: var(--white);

  @media ${({ theme }) => theme.mediaQueries.massive} {
    width: 90%;
  }
  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    /* width: 100%; */
  }
  @media ${({ theme }) => theme.mediaQueries.small} {
    margin-left: 0rem;
  }
`;

export const MainTitle = styled.h1`
  font-size: 7rem;
  z-index: 5;
  border-left: 0.4rem solid var(--main);
  padding-left: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;

  @media ${({ theme }) => theme.mediaQueries.massive} {
    font-size: 6rem;
  }

  @media ${({ theme }) => theme.mediaQueries.mediumLarge} {
    font-size: 4rem;
  }
  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    font-size: 3.5rem;
  }
  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 3rem;
    padding-left: 1rem;
  }
  @media ${({ theme }) => theme.mediaQueries.smaller} {
    font-size: 2.5rem;
  }
  @media ${({ theme }) => theme.mediaQueries.smallest} {
    font-size: 2rem;
  }
`;

export const Description = styled.h3`
  z-index: 5;
  font-size: 2.5rem;
  font-weight: 500;
  padding-left: 2rem;
  width: 70%;
  border-left: 0.7rem solid transparent;

  @media ${({ theme }) => theme.mediaQueries.mediumLarge} {
    font-size: 2rem;
  }

  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    font-size: 1.7rem;
    width: 80%;
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    font-size: 1.6rem;
    padding-left: 1rem;
    width: 85%;
  }

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    font-size: 1.5rem;
  }

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    font-size: 1.4rem;
  }
`;
