import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//Common
import { Container } from '../../components/common/elements';

//Components
import DesktopMenu from '../../components/navigation-bar/desktop-menu/DesktopMenu';
import MobileMenu from '../../components/navigation-bar/mobile-menu/MobileMenu';
import Logo from '../../components/logo/Logo';
import Burger from './mobile-menu/burger/Burger';

//Styles
import { StyledNavigation, Wrapper } from './NavigationBar.styles';

const NavigationBar = () => {
  const [isMobile, setIsMobile] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const changeMobile = () => {
    window.matchMedia('(max-width: 37.5em)').matches
      ? setIsMobile(true)
      : setIsMobile(false);
  };

  useEffect(() => {
    changeMobile();
    window.addEventListener('resize', changeMobile);
    return () => window.removeEventListener('resize', changeMobile);
  }, []);

  const handleItemClick = () => {
    setOpen(false);
  };

  return (
    <StyledNavigation id="navigationBar">
      <Container>
        <Wrapper isMobile={isMobile}>
          <Logo />
          {isMobile ? (
            <>
              <Burger open={isOpen} setOpen={setOpen} />
              <MobileMenu
                open={isOpen}
                setOpen={setOpen}
                handleItemClick={handleItemClick}
              />
            </>
          ) : (
            <DesktopMenu />
          )}
        </Wrapper>
      </Container>
    </StyledNavigation>
  );
};

NavigationBar.propTypes = {};

export default NavigationBar;
