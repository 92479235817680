import styled from 'styled-components';

export const Container = styled.div`
  max-width: 124rem;
  z-index: 2;
  padding: 0 4rem;
  margin: 0 auto;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    padding: 0 2.5rem;
  }

  @media ${({ theme }) => theme.mediaQueries.smallest} {
    padding: 0 2rem;
  }
`;
