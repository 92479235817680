import styled from 'styled-components';
import { Link } from 'react-scroll';

export const StyledLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  position: relative;
  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    font-size: 1.4rem;
    padding: 1rem 1.5rem;
  }

  &:after {
    position: absolute;
    transition: all 0.5s;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: var(--main);
    height: 2px;
  }
  &:hover:after {
    width: 90%;
  }
`;
